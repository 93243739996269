html, body {
    margin: 0;
    padding: 0;
    border: 0;
}

html {
    height: 100%;
}

body {
    background:$color-light;

    @media (prefers-color-scheme: dark) {
        background:$color-dark;
    }
}

.wrapper {
    @extend .container;
}

header {
    margin: 1.5rem 0 4rem;
}

section {
    @include make-row();
    margin-bottom: 2rem;

    article {
        @include make-col-ready();
        @include make-col(10);
        @include make-col-offset(1);

        @include media-breakpoint-down(md) {
            @include make-col(12);
            @include make-col-offset(0);
        }

        h1 {
            margin-bottom: 1.5rem;
            font-family: $font-montserrat;
        }
        
        .rules-about {
            font-size: 1.25rem;
            line-height: 1.5;
        }

        .the-rules {
            margin: 0;
            padding: 0;
            // list-style-position: inside;
            list-style: none;
            counter-reset: my-awesome-counter;

            li {
                margin-top: 1.5rem;
                counter-increment: my-awesome-counter;

                &:before {
                    content: counter(my-awesome-counter) ". ";
                    color: rgba($color-dark, 0.7);

                    @media (prefers-color-scheme: dark) {
                        color: rgba($color-light, 0.4);
                    }
                }
            }
        }
    }
}

footer {
    @include make-row();
    height: 2.5rem;
}