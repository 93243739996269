@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Forum&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;600&display=swap');


$font-forum: 'Forum', serif;
$font-work: 'Work Sans', sans-serif;
$font-montserrat: 'Montserrat', sans-serif;


// Global Styles
body {
    font-family: $font-work;
    color: $color-dark;
    font-size: 16px;

    @media (prefers-color-scheme: dark) {
        color: $color-light;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $font-forum;
}

h1 {
    font-size: 3.5rem;
    font-size: clamp(2rem, 4vw, 3.5rem);
}